<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'EmptyLayout',
  created: function() {
    if(!!this.$store.getters.loginUserToken) this.$store.dispatch('initSearchDataForCodeRequest')
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
</style>
